<template>
  <div class="info-table">
    <div style="display: flex">
      <span class="inner-title">血管信息{{ isReadonly ? '' : '录入' }}</span>
    </div>
    <div style="margin: 10px 0 0 10px">
      <div v-show="!isReadonly">
        <el-button
          size="mini"
          type="primary"
          class="commonBtn"
          @click="bloodVesselInsert"
          :disabled="isReadonly"
        >
          新增
        </el-button>
        <el-button
          size="mini"
          type="warning"
          class="commonBtn"
          @click="bloodVesselModify"
          :disabled="isReadonly"
        >
          修改
        </el-button>
        <el-button
          size="mini"
          type="danger"
          class="commonBtn"
          @click="bloodVesselDelete"
          :disabled="isReadonly"
        >
          删除
        </el-button>
      </div>
      <el-table
        class="inner-table"
        highlight-current-row
        :data="bloodVesselTableData"
        :header-cell-style="{ padding: 0 }"
        height="200px"
        style="width: 99%"
        :key="tableKey"
        @row-click="_rowClick"
        @row-dblclick="_doubleModify"
        border
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="lesionSite" label="病变部位"></el-table-column>
        <el-table-column prop="aneurysmType" label="动脉瘤类型"></el-table-column>
        <el-table-column prop="dutyRupturedAneurysm" label="是否为责任破裂动脉瘤"></el-table-column>
        <el-table-column prop="maxDiameter" label="最大径"></el-table-column>
        <el-table-column prop="neckWidth" label="颈宽"></el-table-column>
        <el-table-column prop="tumorHeight" label="瘤高"></el-table-column>
        <el-table-column prop="tumorWidth" label="瘤宽"></el-table-column>
        <el-table-column prop="type" label="类型"></el-table-column>
        <el-table-column prop="regularity" label="规则度"></el-table-column>
        <el-table-column prop="hasInterventionalTherapy" label="既往是否介入治疗"></el-table-column>
        <el-table-column
          prop="hasCoilImplantation"
          label="既往有无弹簧圈植入情况"
        ></el-table-column>
        <el-table-column prop="coilType" label="弹簧圈类型"></el-table-column>
        <el-table-column prop="coilCount" label="既往弹簧圈植入数量"></el-table-column>
        <el-table-column
          prop="hasBracketImplantation"
          label="既往有无支架植入情况"
        ></el-table-column>
        <el-table-column prop="bracketType" label="支架类型"></el-table-column>
        <el-table-column prop="bracketCount" label="既往支架植入数量"></el-table-column>
        <el-table-column prop="handlingOpinion" label="本次处理意见"></el-table-column>
      </el-table>
      <nerve-aneurysm-info-dialog
        ref="aneurismInfoDialog"
        :isReadonly="isReadonly"
        @save="_dialogSave"
      ></nerve-aneurysm-info-dialog>
    </div>
    <div v-show="selectedInfo?.handlingOpinion?.includes('本次治疗')" class="surgery-information">
      <el-tabs v-model="tabs_index" type="border-card" @tab-click="handleClick">
        <!-- 弹簧圈栓塞 -->
        <el-tab-pane
          :key="key_list[0]"
          v-if="
            selectedInfo &&
            selectedInfo.handlingOpinion &&
            handlingOpinionList.includes('本次治疗') &&
            handlingOpinionList.includes('弹簧圈栓塞')
          "
        >
          <span slot="label">
            <i
              :class="nerveAneurysmCoilEmbolismDetail.id != null ? 'icon-finished' : 'icon-warn'"
            ></i>
            弹簧圈栓塞
          </span>
          <div class="infoEntry-table-tab">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">该部位介入是否成功</div>
                <cqt-radio
                  style="margin-left: 15px"
                  :value.sync="nerveAneurysmCoilEmbolismDetail.isSucceed"
                  :isReadonly="isReadonly"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </cqt-radio>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">治疗后动脉瘤填塞程度</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/ANEURYSM_coil_embolism_aneurysm_packing_degree"
                  :value.sync="nerveAneurysmCoilEmbolismDetail.aneurysmPackingDegree"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">弹簧圈类型</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/ANEURYSM_detail_coil_type"
                  :value.sync="nerveAneurysmCoilEmbolismDetail.coilType"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">该部位植入弹簧圈数量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    size="mini"
                    unit="枚"
                    v-model="nerveAneurysmCoilEmbolismDetail.coilCount"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <!-- 支架辅助弹簧圈栓塞 -->
        <el-tab-pane
          :key="key_list[1]"
          v-if="
            selectedInfo &&
            selectedInfo.handlingOpinion &&
            handlingOpinionList.includes('本次治疗') &&
            handlingOpinionList.includes('支架辅助弹簧圈栓塞')
          "
        >
          <span slot="label">
            <i :class="nerveAneurysmBacpDetail.id != null ? 'icon-finished' : 'icon-warn'"></i>
            支架辅助弹簧圈栓塞
          </span>
          <div class="infoEntry-table-tab">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">该部位介入是否成功</div>
                <cqt-radio
                  style="margin-left: 15px"
                  :value.sync="nerveAneurysmBacpDetail.isSucceed"
                  :isReadonly="isReadonly"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </cqt-radio>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">治疗后动脉瘤填塞程度</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/ANEURYSM_bacp_aneurysm_packing_degree"
                  :value.sync="nerveAneurysmBacpDetail.aneurysmPackingDegree"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">支架类型</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/ANEURYSM_detail_bracket_type"
                  :value.sync="nerveAneurysmBacpDetail.bracketType"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">既往支架植入数量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    size="mini"
                    unit="枚"
                    v-model="nerveAneurysmBacpDetail.bracketCount"
                  />
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">弹簧圈类型</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/ANEURYSM_detail_coil_type"
                  :value.sync="nerveAneurysmBacpDetail.coilType"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">既往弹簧圈植入数量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    size="mini"
                    unit="枚"
                    v-model="nerveAneurysmBacpDetail.coilCount"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <!-- 球囊辅助弹簧圈栓塞 -->
        <el-tab-pane
          :key="key_list[2]"
          v-if="
            selectedInfo &&
            selectedInfo.handlingOpinion &&
            handlingOpinionList.includes('本次治疗') &&
            handlingOpinionList.includes('球囊辅助弹簧圈栓塞')
          "
        >
          <span slot="label">
            <i :class="nerveAneurysmBaceDetail.id != null ? 'icon-finished' : 'icon-warn'"></i>
            球囊辅助弹簧圈栓塞
          </span>
          <div class="infoEntry-table-tab">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">该部位介入是否成功</div>
                <cqt-radio
                  style="margin-left: 15px"
                  :value.sync="nerveAneurysmBaceDetail.isSucceed"
                  :isReadonly="isReadonly"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </cqt-radio>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">治疗后动脉瘤填塞程度</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/ANEURYSM_bace_aneurysm_packing_degree"
                  :value.sync="nerveAneurysmBaceDetail.aneurysmPackingDegree"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">该部位植入球囊类型</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/ANEURYSM_bace_balloon_type"
                  :value.sync="nerveAneurysmBaceDetail.balloonType"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">该部位植入球囊数量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    size="mini"
                    unit="个"
                    v-model="nerveAneurysmBaceDetail.balloonCount"
                  />
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">弹簧圈类型</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/ANEURYSM_detail_coil_type"
                  :value.sync="nerveAneurysmBaceDetail.coilType"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">该部位植入弹簧圈数量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    size="mini"
                    unit="枚"
                    v-model="nerveAneurysmBaceDetail.coilCount"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <!-- 开颅夹闭 -->
        <el-tab-pane
          :key="key_list[3]"
          v-if="
            selectedInfo &&
            selectedInfo.handlingOpinion &&
            handlingOpinionList.includes('本次治疗') &&
            handlingOpinionList.includes('开颅夹闭')
          "
        >
          <span slot="label">
            <i
              :class="nerveAneurysmCraniotomyClipDetail.id != null ? 'icon-finished' : 'icon-warn'"
            ></i>
            开颅夹闭
          </span>
          <div class="infoEntry-table-tab">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">夹闭手术是否成功</div>
                <cqt-radio
                  style="margin-left: 15px"
                  :value.sync="nerveAneurysmCraniotomyClipDetail.isSucceed"
                  :isReadonly="isReadonly"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </cqt-radio>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">夹闭效果</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/ANEURYSM_craniotomy_clip_effect"
                  :value.sync="nerveAneurysmCraniotomyClipDetail.clipEffect"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">瘤夹类型</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/ANEURYSM_craniotomy_clip_type"
                  :value.sync="nerveAneurysmCraniotomyClipDetail.clipType"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">瘤夹数量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    size="mini"
                    unit="枚"
                    v-model="nerveAneurysmCraniotomyClipDetail.clipCount"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane
          :key="key_list[4]"
          v-if="
            selectedInfo &&
            selectedInfo.handlingOpinion &&
            handlingOpinionList.includes('本次治疗') &&
            handlingOpinionList.includes('血流导向装置')
          "
        >
          <span slot="label">
            <i :class="nerveAneurysmBFGDDetail.id != null ? 'icon-finished' : 'icon-warn'"></i>
            血流导向装置
          </span>
          <div class="infoEntry-table-tab">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">该部位介入是否成功</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/ANEURYSM_bfgd_succeed"
                  :value.sync="nerveAneurysmBFGDDetail.isSucceed"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">装置类型</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/ANEURYSM_bfgd_devicetype"
                  :value.sync="nerveAneurysmBFGDDetail.deviceType"
                />
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import NerveAneurysmInfoDialog from './NerveAneurysmInfoDialog.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'
export default {
  name: 'NerveAneurysmInfoEntry',
  components: {
    NerveAneurysmInfoDialog,

    CqtSelect,
    CqtInput,
    CqtRadio
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: () => false
    },
    formId: {
      type: String,
      require: true
    },
    allDetail: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    selectedInfo: {
      handler(val) {
        if (val) {
          this.handlingOpinionList = val.handlingOpinion?.split('+') || []
          this.getAllSuggestions(val)
        }
      },
      immediate: true
    },
    allDetail: {
      handler(val) {
        if (val) {
          this._initInfoValue()
        }
      }
    }
  },
  data() {
    return {
      bloodVesselTableData: [],
      selectedInfo: null,
      tableKey: null,
      handlingOpinionList: [],
      key_list: [1, 2, 3, 4, 5],
      tabs_index: '0',
      // 弹簧圈栓塞
      nerveAneurysmCoilEmbolismDetail: {
        isSucceed: null, //该部位介入是否成功
        aneurysmPackingDegree: null, //治疗后动脉瘤填塞程度
        coilCount: null, //该部位植入弹簧圈数量
        coilType: null //
      },
      // 支架辅助弹簧圈栓塞
      nerveAneurysmBacpDetail: {
        isSucceed: null, //该部位介入是否成功
        aneurysmPackingDegree: null, //治疗后动脉瘤填塞程度
        coilCount: null, //既往弹簧圈植入数量
        coilType: null, //
        bracketCount: null, //既往支架植入数量
        bracketType: null //支架类型
      },
      // 弹簧圈栓塞
      nerveAneurysmBaceDetail: {
        isSucceed: null, //该部位介入是否成功
        aneurysmPackingDegree: null, //治疗后动脉瘤填塞程度
        coilCount: null, //该部位植入弹簧圈数量
        coilType: null, //
        balloonCount: null, //该部位植入球囊数量
        balloonType: null //该部位植入球囊类型
      },
      // 开颅夹闭
      nerveAneurysmCraniotomyClipDetail: {
        isSucceed: null, //夹闭手术是否成功
        clipEffect: null, //夹闭效果
        clipCount: null, //瘤夹数量
        clipType: null //瘤夹类型
      },
      // 血流导向装置
      nerveAneurysmBFGDDetail: {
        isSucceed: null,
        deviceType: null
      }
    }
  },
  created() {},
  methods: {
    // 页码切换
    handleClick(tab) {
      this.tabs_index = tab.index
    },
    async _initInfoValue() {
      let url = `/v1/webconsole/eform/extendfields/${this.formId}/${this.allDetail.nerveAneurysmDetail.id}`
      let formData = new FormData()
      formData.append('elementName', 'nerveAneurysmDetail')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            console.log('bloodVesselTableData', res.data)
            this.bloodVesselTableData = res.data
            for (let i = 0; i < this.bloodVesselTableData.length; i++) {
              // 耗材
              const element = this.bloodVesselTableData[i]
              // 处理意见handlingSuggestions表示有二级分类
              if (element.handlingOpinion) {
                this.getAllSuggestions(element, 'init')
              }
            }
          }
        },
        () => {}
      )
    },
    getAllSuggestions(val, type) {
      if (!val.handlingOpinion?.includes('本次治疗')) return
      // 弹簧圈栓塞
      if (val.handlingOpinion?.includes('弹簧圈栓塞')) {
        this._getNerveAneurysmCoilEmbolismInfo(val, type)
      }
      // 支架辅助弹簧圈栓塞
      if (val.handlingOpinion?.includes('支架辅助弹簧圈栓塞')) {
        this._getNerveAneurysmBacpInfo(val, type)
      }
      // 球囊辅助弹簧圈栓塞
      if (val.handlingOpinion?.includes('球囊辅助弹簧圈栓塞')) {
        this._getNerveAneurysmBaceInfo(val, type)
      }
      // 开颅夹闭
      if (val.handlingOpinion?.includes('开颅夹闭')) {
        this._getNerveAneurysmCraniotomyClipInfo(val, type)
      }
      // 血流导向装置
      if (val.handlingOpinion?.includes('血流导向装置')) {
        this._getNerveAneurysmBFGDInfo(val, type)
      }
    },

    bloodVesselInsert() {
      this.$refs.aneurismInfoDialog.Show()
    },
    // 血管编辑
    bloodVesselModify() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条血管信息')
      if (this.selectedInfo) {
        this.$refs.aneurismInfoDialog.Show(this.selectedInfo)
      }
    },
    // 血管删除
    bloodVesselDelete() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条血管信息')
      this.$confirm('是否删除该血管信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.selectedInfo.id) {
          const index = this.bloodVesselTableData.findIndex(
            (item) => item.id === this.selectedInfo.id
          )
          if (index !== -1) {
            this.bloodVesselTableData.splice(index, 1)
          }
        } else {
          const index = this.bloodVesselTableData.findIndex(
            (item) => item.ids === this.selectedInfo.ids
          )
          if (index !== -1) {
            this.bloodVesselTableData.splice(index, 1)
          }
        }
        this.selectedInfo = null
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      })
    },
    _rowClick(row) {
      // if (this.isReadonly) return
      this.selectedInfo = null
      this.selectedInfo = row

      if (row.id) {
        this.key_list = [
          row.id,
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000)
        ]
      } else {
        this.key_list = [
          row.ids,
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000)
        ]
      }
    },
    _doubleModify(row) {
      this.selectedInfo = null
      this.selectedInfo = row
      this.$refs.aneurismInfoDialog.Show(this.selectedInfo)
    },
    _dialogSave(val) {
      this.selectedInfo = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.bloodVesselTableData.length; i++) {
          const element = this.bloodVesselTableData[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.bloodVesselTableData[i] = val
            this.$set(this.bloodVesselTableData, i, this.bloodVesselTableData[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.bloodVesselTableData.push(val)
        }
      } else {
        for (let i = 0; i < this.bloodVesselTableData.length; i++) {
          const element = this.bloodVesselTableData[i]
          if (val.id === element.id) {
            this.bloodVesselTableData[i] = val
            this.$set(this.bloodVesselTableData, i, this.bloodVesselTableData[i])
            break
          }
        }
      }
    },
    // 弹簧圈栓塞
    async _getNerveAneurysmCoilEmbolismInfo(val, type) {
      // 轮询赋值
      if (type === 'init') {
        try {
          const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.id}`
          let formData = new FormData()
          formData.append('elementName', 'nerveAneurysmCoilEmbolism')
          const res = await this.$api.post(url, formData)
          if (res.data && res.data.data && res.data.data.length) {
            val.nerveAneurysmCoilEmbolism = res.data.data[0]
            this.nerveAneurysmCoilEmbolismDetail = val.nerveAneurysmCoilEmbolism
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.nerveAneurysmCoilEmbolismDetail = val.nerveAneurysmCoilEmbolism || {
          ...this.$options.data().nerveAneurysmCoilEmbolismDetail
        }

        val.nerveAneurysmCoilEmbolism = this.nerveAneurysmCoilEmbolismDetail
      }
    },
    // 支架辅助弹簧圈栓塞
    async _getNerveAneurysmBacpInfo(val, type) {
      // 轮询赋值
      if (type === 'init') {
        try {
          const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.id}`
          let formData = new FormData()
          formData.append('elementName', 'nerveAneurysmBACP')
          const res = await this.$api.post(url, formData)
          if (res.data && res.data.data && res.data.data.length) {
            val.nerveAneurysmBACP = res.data.data[0]
            this.nerveAneurysmBacpDetail = val.nerveAneurysmBACP
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.nerveAneurysmBacpDetail = val.nerveAneurysmBACP || {
          ...this.$options.data().nerveAneurysmBacpDetail
        }
        val.nerveAneurysmBACP = this.nerveAneurysmBacpDetail
      }
    },
    // 球囊辅助弹簧圈栓塞
    async _getNerveAneurysmBaceInfo(val, type) {
      // 轮询赋值
      if (type === 'init') {
        try {
          const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.id}`
          let formData = new FormData()
          formData.append('elementName', 'nerveAneurysmBACE')
          const res = await this.$api.post(url, formData)
          if (res.data && res.data.data && res.data.data.length) {
            val.nerveAneurysmBACE = res.data.data[0]
            this.nerveAneurysmBaceDetail = val.nerveAneurysmBACE
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.nerveAneurysmBaceDetail = val.nerveAneurysmBACE || {
          ...this.$options.data().nerveAneurysmBaceDetail
        }
        val.nerveAneurysmBACE = this.nerveAneurysmBaceDetail
      }
    },

    // 开颅夹闭
    async _getNerveAneurysmCraniotomyClipInfo(val, type) {
      // 轮询赋值
      if (type === 'init') {
        try {
          const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.id}`
          let formData = new FormData()
          formData.append('elementName', 'nerveAneurysmCraniotomyClip')
          const res = await this.$api.post(url, formData)
          if (res.data && res.data.data && res.data.data.length) {
            val.nerveAneurysmCraniotomyClip = res.data.data[0]
            this.nerveAneurysmCraniotomyClipDetail = val.nerveAneurysmCraniotomyClip
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.nerveAneurysmCraniotomyClipDetail = val.nerveAneurysmCraniotomyClip || {
          ...this.$options.data().nerveAneurysmCraniotomyClipDetail
        }
        val.nerveAneurysmCraniotomyClip = this.nerveAneurysmCraniotomyClipDetail
      }
    },

    // 血流导向装置
    async _getNerveAneurysmBFGDInfo(val, type) {
      // 轮询赋值
      if (type === 'init') {
        try {
          const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.id}`
          let formData = new FormData()
          formData.append('elementName', 'nerveAneurysmBFGD')
          const res = await this.$api.post(url, formData)
          if (res.data && res.data.data && res.data.data.length) {
            val.nerveAneurysmBFGD = res.data.data[0]
            this.nerveAneurysmBFGDDetail = val.nerveAneurysmBFGD
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.nerveAneurysmBFGDDetail = val.nerveAneurysmBFGD || {
          ...this.$options.data().nerveAneurysmBFGDDetail
        }
        val.nerveAneurysmBFGD = this.nerveAneurysmBFGDDetail
      }
    }
  }
}
</script>

<style scoped lang="less">
.infoEntry-table-tab {
  display: flex;
  width: 99%;
  padding: 15px 0;
}
.info-table {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px 15px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
  /deep/.el-table .cell {
    white-space: normal;
  }
  /deep/.top-item-row .item-row span {
    margin: 0 0 0 5px;
  }
  button {
    margin-right: 0.5rem;
  }
}
.inner-title {
  position: absolute;
  top: -12px;
  left: 12px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
}
.inner-table {
  margin-top: 10px;
  border: 1px solid black;
  height: 200px;
  position: relative;
}
.inner-tabs {
  margin: 10px 10px;
  height: 250px;
}
.item-inline {
  margin: 15px 25px;
  display: flex;
  justify-content: flex-end;
}
.item-inline > span {
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
}
.item-column {
  display: flex;
  flex-direction: column;
}
.button-panel {
  width: 100%;
  bottom: 0;
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: space-around;
}
.icon-finished::before {
  content: url('../../../../assets/img/finished.png');
}
.icon-warn::before {
  content: url('../../../../assets/img/warn.png');
}

.surgery-information {
  // border-style: solid;
  //     border-width: medium;
  //     border-color: #E4E7ED;
  margin-top: 30px;
  margin-bottom: 10px;

  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .input-form-item-row {
    width: 100%;
    margin: 0 0.5rem;
    display: flex;
    justify-content: flex-start;
  }

  .input-form-item {
    min-width: 30%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;
    .input_has_unit {
      margin: 0;
    }
  }
  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 45%;
      margin: 0.4rem 1rem;
    }
  }

  .input-form-item-label {
    width: 140px;
  }
}
</style>
