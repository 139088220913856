<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 20%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}
.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.input_has_unit {
  width: 180px;
}
</style>

<template>
  <CmDialogBorder size="big" :zIndex="zIndex" title="病变编辑" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <div class="item-row">
        <span>病变部位</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/ANEURYSM_detail_lesion_site"
          :value.sync="aneurismTableInfo.lesionSite"
        />
      </div>
      <div class="item-row">
        <span>动脉瘤类型</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/ANEURYSM_detail_aneurysm_type"
          :value.sync="aneurismTableInfo.aneurysmType"
        />
      </div>
      <div class="item-row">
        <span>是否为责任破裂动脉瘤</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/ANEURYSM_detail_duty_ruptured_aneurysm"
          :value.sync="aneurismTableInfo.dutyRupturedAneurysm"
        />
      </div>
      <div class="item-row">
        <span>最大径</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="mm"
          v-model="aneurismTableInfo.maxDiameter"
        />
      </div>
      <div class="item-row">
        <span>颈宽</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="mm"
          v-model="aneurismTableInfo.neckWidth"
        />
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>瘤高</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="mm"
          v-model="aneurismTableInfo.tumorHeight"
        />
      </div>
      <div class="item-row">
        <span>瘤宽</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="mm"
          v-model="aneurismTableInfo.tumorWidth"
        />
      </div>
      <div class="item-row">
        <span>类型</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/ANEURYSM_detail_type"
          :value.sync="aneurismTableInfo.type"
        />
      </div>
      <div class="item-row">
        <span>规则度</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/ANEURYSM_detail_regularity"
          :value.sync="aneurismTableInfo.regularity"
        />
      </div>
      <div class="item-row">
        <span>既往是否有介入治疗史</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/ANEURYSM_detail_has_interventional_therapy"
          :value.sync="aneurismTableInfo.hasInterventionalTherapy"
        />
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>既往有无弹簧圈植入情况</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/ANEURYSM_detail_has_coil_bracket_implantation"
          :value.sync="aneurismTableInfo.hasCoilImplantation"
        />
      </div>
      <div class="item-row">
        <span>弹簧圈类型</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/ANEURYSM_detail_coil_type"
          :value.sync="aneurismTableInfo.coilType"
        />
      </div>
      <div class="item-row">
        <span>既往弹簧圈植入数量</span>
        <div>
          <cqt-input
            :isReadonly="isReadonly"
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            size="mini"
            unit="枚"
            v-model="aneurismTableInfo.coilCount"
          />
        </div>
      </div>
      <div class="item-row"></div>
      <div class="item-row"></div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>既往有无支架植入情况</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/ANEURYSM_detail_has_bracket_implantation"
          :value.sync="aneurismTableInfo.hasBracketImplantation"
        />
      </div>
      <div class="item-row">
        <span>支架类型</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/ANEURYSM_detail_bracket_type"
          :value.sync="aneurismTableInfo.bracketType"
        />
      </div>
      <div class="item-row">
        <span>既往支架植入数量</span>
        <div>
          <cqt-input
            :isReadonly="isReadonly"
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            size="mini"
            unit="枚"
            v-model="aneurismTableInfo.bracketCount"
          />
        </div>
      </div>
      <div class="item-row"></div>
      <div class="item-row"></div>
    </div>
    <div class="top-item-row">
      <el-col v-show="!isReadonly" :span="14" title="处理意见">
        <el-divider content-position="left">处理意见</el-divider>
        <el-radio label="择期弹簧圈栓塞" v-model="handlingOpinions">择期弹簧圈栓塞</el-radio>
        <el-radio label="择期开颅夹闭" v-model="handlingOpinions">择期开颅夹闭</el-radio>
        <el-radio label="药物治疗" v-model="handlingOpinions">药物治疗</el-radio>
        <el-radio label="其他治疗" v-model="handlingOpinions">其他治疗</el-radio>
        <el-radio label="本次治疗" v-model="handlingOpinions">本次治疗</el-radio>
      </el-col>
      <el-col v-show="isReadonly" :span="14" title="处理意见">
        <el-divider content-position="left">处理意见</el-divider>
        <el-tag type="info" style="margin-left: 20px" v-model="handlingOpinions">
          {{ handlingOpinions }}
        </el-tag>
      </el-col>
      <el-col v-show="!isReadonly" :span="10">
        <el-divider v-if="handlingOpinions === '本次治疗'" content-position="left">
          本次治疗
        </el-divider>
        <current-checkbox-select
          v-if="handlingOpinions === '本次治疗'"
          v-model="currentTreatment"
          :currentTreatment="this.currentTreatment"
        ></current-checkbox-select>
      </el-col>
      <el-col v-show="isReadonly" :span="10">
        <el-divider v-if="handlingOpinions === '本次治疗'" content-position="left">
          本次治疗
        </el-divider>
        <el-tag type="info" style="margin-left: 20px" v-model="currentTreatment">
          {{ currentTreatment }}
        </el-tag>
      </el-col>
    </div>
    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
        v-show="!isReadonly"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../plugins/clone'
import CurrentCheckboxSelect from './CurrentCheckboxSelect.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
export default {
  name: 'NerveAneurysmInfoDialog',
  components: {
    CmDialogBorder,
    CurrentCheckboxSelect,

    CqtSelect,
    CqtInput
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      handlingOpinions: ' ',
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      currentTreatment: '',
      aneurismTableInfo: {
        lesionSite: null, //病变部位
        aneurysmType: null, //动脉瘤类型
        dutyRupturedAneurysm: null, //是否为责任破裂动脉瘤
        maxDiameter: null, //最大径
        neckWidth: null, //颈宽
        tumorHeight: null, //瘤高
        tumorWidth: null, //瘤宽
        type: null, //类型
        regularity: null, //规则度
        hasInterventionalTherapy: null, //是否有介入治疗史
        hasCoilImplantation: null, //既往有无弹簧圈植入情况
        hasBracketImplantation: null, //既往有无支架植入情况
        coilType: null, //弹簧圈类型
        bracketType: null, //支架类型
        coilCount: null, //既往弹簧圈植入数量
        bracketCount: null, //既往支架植入数量
        handlingOpinion: null //处理意见
      }
    }
  },
  watch: {
    handlingOpinions: {
      handler(val) {
        this.aneurismTableInfo.handlingOpinion = val
      }
    },

    currentTreatment: {
      handler(val) {
        this.aneurismTableInfo.handlingOpinion = '本次治疗'

        if (val && val.length > 0) {
          this.aneurismTableInfo.handlingOpinion = ['本次治疗', ...val.split('|')].join('+')
        }
      }
    }
  },
  created() {},
  methods: {
    Show(bloodVessel) {
      this.aneurismTableInfo = {}
      this.currentTreatment = ''
      if (bloodVessel) this.aneurismTableInfo = deepClone(bloodVessel)

      let sugs = this.aneurismTableInfo.handlingOpinion?.split('+') || []
      this.handlingOpinions = sugs[0] || ''
      if (sugs.length > 0) {
        this.currentTreatment = sugs?.slice(1).join('|')
      }

      this.isShow = true
    },
    Save() {
      if (this.isReadonly) return
      if (this.aneurismTableInfo.lesionSite === null || this.aneurismTableInfo.lesionSite === '') {
        return this.$message.warning('病变部位不能为空')
      }

      var idx = this.aneurismTableInfo.handlingOpinion?.indexOf('++') || -1
      if (idx != -1) {
        var a = this.aneurismTableInfo.handlingOpinion.substr(0, idx + 1)
        var b = this.aneurismTableInfo.handlingOpinion.substr(idx + 2)
        this.aneurismTableInfo.handlingOpinion = a + b
      }

      this.$emit('save', this.aneurismTableInfo)
      this.Close()
    },

    Close() {
      this.isShow = false
      // this.handlingOpinions = ''
    }
  }
}
</script>
